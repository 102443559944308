<script>
import { toBase64 } from '@shared/services/Utils';
// import { refreshCurrentTrainingOnUpdate } from '@app/mixins/forms';

export default {
  // mixins: [refreshCurrentTrainingOnUpdate],
  forms: [
    {
      name: {
        label: 'Titre',
        type: 'text',
        column: 'is-12',
        inputAttrs: {
          required: true,
          maxlength: 128,
          hasCounter: false,
        },
      },
      slug: {
        label: 'URL (pour le SEO)',
        type: 'text',
        column: 'is-12',
        hasAddonLeft: true,
        hasAddonRight: true,
        inputAttrs: {
          maxlength: 128,
          hasCounter: false,
          expanded: true,
        },
      },
      billing_address_enabled: {
        switchLabel: 'Demander l\'adresse de facturation lors des achats',
        type: 'switch',
        column: 'is-12',
        tooltip: `
          L'adresse de facturation ne sera demandée que si la formation est payante.
        `,
        tooltipAttrs: {
          multilined: true,
        },
      },
      // program_enabled: {
      //   switchLabel: 'Afficher la liste des leçons sur votre page de formation',
      //   type: 'switch',
      //   column: 'is-12',
      // },
      // program_toggled: {
      //   switchLabelHTML: 'Afficher la liste des leçons <strong>depliée</strong> par défaut',
      //   type: 'switch',
      //   column: 'is-12',
      //   ifModelName: 'program_enabled',
      //   ifModelValue: true,
      // },
      picture: {
        label: 'Image',
        type: 'upload',
        inputAttrs: { accept: 'image/*' },
        column: 'is-6',
      },
    },
  ],
  created() {
    this.hydrate();
  },
  data() {
    return {
      isLoading: {
        0: false,
        1: false,
      },
      bundle: {
        name: '',
        slug: '',
        feature_options: {
          billing_address: {
            enabled: false,
          },
        },
        // program_enabled: false,
        // program_toggled: false,
        picture: '',
      },
    };
  },
  computed: {
    currentBundle() {
      return this.$store.state.bundles.current;
    },
    authStore() {
      return this.$store.getters['auth/store'];
    },
    bundleIsDraft() {
      return this.currentBundle.status === 'DRAFT';
    },
    bundleURL() {
      const p = this.bundleIsDraft ? `?p=${this.$store.state.auth.previewToken}` : '';

      return `${this.$store.getters['auth/url']}/packs/${this.bundle.slug}${p}`;
    },
  },

  methods: {
    hydrate() {
      Object.keys(this.bundle)
        .forEach((key) => (this.bundle[key] = this.currentBundle[key]));
    },
    onViewDraft(event) {
      if (this.bundleIsDraft) {
        event.preventDefault();
        this.$buefy.dialog.confirm({
          title: 'Pack en brouillon !',
          message: `
            Votre pack est en brouillon. <br>
            Vous pouvez la prévisualiser, mais personne d'autre n'y aura accès.
          `,
          type: 'is-warning',
          focusOn: 'cancel',
          onConfirm: () => window.open(this.bundleURL),
        });
      }
    },
    handlePicture(file) {
      if (!file) {
        this.bundle.picture = null;
        return;
      }

      if (file.size > this.$constants.MAX_IMAGE_SIZE) {
        this.$buefy.dialog.alert(this.$t('messages.file_larger_than', {
          size: '5 Mo',
        }));
        return;
      }

      const loader = this.$buefy.loading.open({ container: this.$refs.form[1] });
      toBase64(file)
        .then((base64) => (this.bundle.picture = base64))
        .finally(() => loader.close());
    },
    handle(form, fk) {
      const bundle = {};

      Object.keys(form)
        .forEach((key) => (bundle[key] = this.bundle[key]));

      bundle.feature_options = { ...this.bundle.feature_options };

      if (bundle.picture && bundle.picture.startsWith('http')) {
        delete bundle.picture;
      }

      this.isLoading[fk] = true;
      this.$store.dispatch('bundles/update', { uuid: this.currentBundle.uuid, bundle, isCurrent: true })
        .then(() => this.$showMessage.success())
        .finally(() => (this.isLoading[fk] = false));
    },
  },
};
</script>

<template>
  <AppFeatureWrapper>
    <template #title>
      Paramètres
    </template>
    <template #content>
      <p>
        Détaillez tous les aspects généraux de votre pack comme le titre, l'url
        ou encore l'image de mise en avant.
      </p>
    </template>

    <template #form>
      <form v-for="(form, fk) in $options.forms" :key="(form, fk)" ref="form" class="box"
        @submit.prevent="dataIsValid(isLoading[fk]) && handle(form, fk)">
        <div class="columns is-multiline">
          <div v-for="(field, key) in form"
            v-show="field.ifModelName == null || bundle[field.ifModelName] == field.ifModelValue" :key="key"
            class="column" :class="field.column">
            <BaseField v-if="key == 'billing_address_enabled'" v-model="bundle.feature_options.billing_address.enabled"
              :field="field" />
            <BaseField v-else-if="key == 'picture'" :field="field" :value="bundle[key]" @input="handlePicture" />
            <BaseField v-else-if="key == 'slug'" v-model="bundle[key]" :field="field">
              <template #addonLeft>
                <span class="button is-static">
                  {{ $store.getters['auth/domain'] }}/packs/
                </span>
              </template>
              <template #addonRight>
                <a class="button is-primary" :href="bundleURL" target="_blank" @click="onViewDraft">
                  <b-icon icon="link" />
                </a>
              </template>
              <template #help>
                C'est le lien d'accès à votre pack sur Teachizy.
                <a class="tdecoration-underline" :href="bundleURL" target="_blank" @click="onViewDraft">
                  Cliquez ici pour y accéder
                </a>
              </template>
            </BaseField>
            <BaseField v-else v-model="bundle[key]" :field="field" />
          </div>
        </div>

        <b-field class="has-text-right">
          <b-button type="is-primary" native-type="submit" :loading="isLoading[fk]">
            Mettre à jour
          </b-button>
        </b-field>
      </form>
    </template>
  </AppFeatureWrapper>
</template>

<script>
import DOMPurify from 'dompurify';
import { toBase64 } from '@shared/services/Utils';
// import { refreshCurrentTrainingOnUpdate } from '@app/mixins/forms';

export default {
  // mixins: [refreshCurrentTrainingOnUpdate],
  form: {
    title: {
      label: 'Titre SEO (affiché sur Google, Bing, Facebook, LinkedIn...)',
      help: '50 – 60 caractères maximum recommandés par Google',
      type: 'text',
      column: 'is-12',
      inputAttrs: { placeholder: '' },
    },
    slug: {
      label: 'URL (pour le SEO)',
      help: '60 – 80 caractères recommandés',
      type: 'text',
      column: 'is-12',
      hasAddonLeft: true,
      hasAddonRight: true,
      inputAttrs: {
        required: true,
        expanded: true,
      },
    },
    description: {
      label: 'Description SEO (affichée sur Google, Bing, Facebook, LinkedIn...)',
      help: '155 – 160 caractères maximum recommandés par Google',
      type: 'textarea',
      column: 'is-12',
      inputAttrs: { placeholder: '' },
    },
    image: {
      label: 'Image (affichée sur Facebook, LinkedIn, Twitter...)',
      type: 'upload',
      inputAttrs: { accept: 'image/*' },
      column: 'is-8',
    },
  },
  data() {
    return {
      isLoading: false,
      bundle: {
        slug: '',
      },
      seo: {
        title: '',
        description: '',
        image: '',
      },
    };
  },
  computed: {
    currentBundle() {
      return this.$store.state.bundles.current;
    },
    authStore() {
      return this.$store.getters['auth/store'];
    },
    bundleIsDraft() {
      return this.currentBundle.status === 'DRAFT';
    },
    bundleURL() {
      const p = this.bundleIsDraft ? `?p=${this.$store.state.auth.previewToken}` : '';

      return `${this.$store.getters['auth/url']}/packs/${this.bundle.slug}${p}`;
    },
  },
  created() {
    this.hydrate();
  },
  methods: {
    hydrate() {
      this.bundle.slug = this.currentBundle.slug;
      this.seo.title = this.currentBundle.seo_data.title;
      this.seo.description = this.currentBundle.seo_data.description;
      this.seo.image = this.currentBundle.seo_data.image;
      this.setPlaceholders();
    },
    setPlaceholders() {
      const title = `${this.currentBundle.name} - ${this.authStore.name}`;
      const description = this.currentBundle.summary || this.authStore.description;

      this.$options
        .form.title
        .inputAttrs
        .placeholder = title;
      this.$options
        .form.description
        .inputAttrs
        .placeholder = DOMPurify.sanitize(description, { USE_PROFILES: { html: false } });
    },
    onViewDraft(event) {
      if (this.bundleIsDraft) {
        event.preventDefault();
        this.$buefy.dialog.confirm({
          title: 'Pack en brouillon !',
          message: `
            Votre pack est en brouillon. <br>
            Vous pouvez la prévisualiser, mais personne d'autre n'y aura accès.
          `,
          type: 'is-warning',
          focusOn: 'cancel',
          onConfirm: () => window.open(this.bundleURL),
        });
      }
    },
    handlePicture(file) {
      if (!file) {
        this.seo.image = null;
        return;
      }

      if (file.size > this.$constants.MAX_IMAGE_SIZE) {
        this.$buefy.dialog.alert(this.$t('messages.file_larger_than', {
          size: '5 Mo',
        }));
        return;
      }

      const loader = this.$buefy.loading.open({ container: this.$refs.form });
      toBase64(file)
        .then((base64) => (this.seo.image = base64))
        .finally(() => loader.close());
    },
    handle() {
      const bundle = { ...this.bundle };
      const seoData = { ...this.seo };
      const { uuid } = this.currentBundle;

      if (seoData.image && seoData.image.startsWith('http')) {
        seoData.image = seoData.image.replace(`${this.$env.apiResourcesURL}/`, '');
      }

      this.isLoading = true;
      this.$store.dispatch('bundles/update', {
        uuid, isCurrent: true, bundle: { ...bundle, seo_data: seoData },
      })
        .then(() => this.$showMessage.success())
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>

<template>
  <AppFeatureWrapper>
    <template #title>
      Paramètres SEO
    </template>
    <template #content>
      <p>
        Configurez les métadonnées qui améliorent le
        référencement naturel de votre page.
      </p>
      <p>
        Ces données seront également affichées lors des partages sur
        <strong>Facebook</strong>, <strong>Twitter</strong>, <strong>LinkedIn</strong>...
      </p>
      <p>
        <strong>Ces champs doivent être modifiés avec précaution.</strong>
      </p>
      <p>
        Vous ne savez pas ce qu'est le SEO ? <br>
        <strong>Regardez notre tutoriel sur le sujet.</strong>
        <br>
        <a class="has-text-weight-bold" href="https://www.youtube.com/watch?v=szzjkQDfVVo" target="_blank"
          rel="noopener noreferrer">
          Voir le tutoriel
        </a>
      </p>
    </template>

    <template #form>
      <form ref="form" class="box" @submit.prevent="dataIsValid(isLoading) && handle()">
        <div class="columns is-multiline">
          <div v-for="(field, k) in $options.form" :key="k" class="column" :class="field.column">
            <BaseField v-if="k == 'image'" :field="field" :value="seo[k]" @input="handlePicture">
              <template v-if="!seo[k]" #notification>
                Si aucune image n'est ajoutée,
                l'image utilisée pour les réseaux sociaux
                sera celle du pack, ou à défaut, votre logo.
              </template>
            </BaseField>
            <BaseField v-else-if="k == 'slug'" v-model="bundle[k]" :field="field">
              <template #addonLeft>
                <span class="button is-static">
                  {{ $store.getters['auth/domain'] }}/packs/
                </span>
              </template>
              <template #addonRight>
                <a class="button is-primary" :href="bundleURL" target="_blank" @click="onViewDraft">
                  <b-icon icon="link" />
                </a>
              </template>
            </BaseField>
            <BaseField v-else v-model="seo[k]" :field="field" />
          </div>
        </div>

        <b-field class="has-text-right">
          <b-button type="is-primary" native-type="submit" :loading="isLoading">
            Mettre à jour
          </b-button>
        </b-field>
      </form>
    </template>
  </AppFeatureWrapper>
</template>

<script>
import { currentBundleForms } from '@app/mixins/forms';
import TagInput from '@app/components/TagInput.vue';

export default {
  mixins: [currentBundleForms],
  components: { TagInput },
  data: () => ({
    isLoading: false,
    bundle: {
      tags: [],
    },
  }),
};
</script>

<template>
  <AppFeatureWrapper tag="section">
    <template #title>
      Gérez les étiquettes
    </template>
    <template #content>
      <p>
        Les étiquettes sont un très bon moyen pour vous permettre d'organiser vos packs.<br/>
        Elles ne sont pas visibles par vos visiteurs ou apprenants.
      </p>
    </template>
    <template #form>
      <form class="box" @submit.prevent="dataIsValid(isLoading) && handle()">
        <b-field label="Étiquettes">
          <TagInput v-model="bundle.tags" placeholder="Ajoutez votre étiquette puis tapez sur Entrée" allow-new />
        </b-field>

        <b-field class="has-text-right">
          <b-button type="is-primary" native-type="submit" :loading="isLoading">
            Mettre à jour
          </b-button>
        </b-field>
      </form>
    </template>
  </AppFeatureWrapper>
</template>

<style scoped>
.tag-autocomplete>>>.dropdown-item.is-disabled {
  opacity: 1;
  cursor: pointer;
}

.tag-list {
  display: flex;
  gap: 6px;
}
</style>
